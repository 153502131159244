import React from 'react';
import { isWowcherBrand } from '../../helpers/pages';

const TrustBox = () => {
  const isWowcher = isWowcherBrand();
  // Create a reference to the <div> element which will represent the TrustBox
  if (!isWowcher) return '';
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <>
      <div
        className="trustpilot-widget"
        data-businessunit-id="4df2470a00006400050fe2d2" // We need a reference to this element to load the TrustBox in the effect.
        data-locale="en-US" // Renamed this to className.
        data-style-height="20px"
        data-style-width="100%"
        data-template-id="5419b6ffb0d04a076446a9af"
        data-theme="light"
        ref={ref}
        style={{ pointerEvents: 'none' }}
      />
    </>
  );
};
export default TrustBox;
