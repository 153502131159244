import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TrustBox from '../../components/deal/TrustBox';
import GuideContainer from '../../components/guides/GuideContainer';
import ListicleGuideContainer from '../../components/guides/ListicleGuideContainer';
import { trackEvent } from '../../helpers/analytics';
import { getStaticContent } from '../../helpers/url';
import ThemeContext from '../../providers/ThemeProvider';
import { setOnlySSR } from '../../redux/actions/deals';
import { getServerSideEssentials } from '../../redux/actions/ssr';
import { wrapper } from '../../redux/store/store';

const GuidePage = ({
  errorCode,
  deals,
  feedConfig,
  text,
  additionalText,
  listicleDeals,
  listicleView,
}) => {
  const theme = useContext(ThemeContext);
  const [pageNotFound, setPageNotFound] = useState(false);

  useEffect(() => {
    if (errorCode && document) {
      setPageNotFound(true);
    }
  }, [errorCode]);

  useEffect(() => {
    trackEvent('static_page_loaded');
  }, []);

  if (pageNotFound) {
    trackEvent('static_page_404');
    // Import only if needed
    const DynamicComponent = dynamic(() =>
      import('../../components/errors/Form404'),
    );

    return <DynamicComponent statusCode={404} />;
  }

  return (
    <>
      <TrustBox />
      <div className="rel-container">
        <div className="container">
          {!listicleView && text && text.length > 0 && (
            <GuideContainer
              additionalText={additionalText}
              deals={deals}
              feedConfig={feedConfig}
              text={text}
            />
          )}
          {listicleView && text && text.length > 0 && (
            <ListicleGuideContainer listicleDeals={listicleDeals} text={text} />
          )}
        </div>
      </div>
      <style jsx>{`
        .rel-container {
          position: relative;
        }
        .container {
          margin-top: 0;
          padding: 0;
        }
        @media (min-width: ${theme.breakpoints.smUp}) {
          .container {
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
        :global(.trustpilot-widget) {
          padding-bottom: 8px;
          padding-top: 8px;
        }
        @media (max-width: ${theme.breakpoints.smDown}) {
          :global(.trustpilot-widget) {
            padding-bottom: 8px;
            padding-top: 8px;
          }
        }
      `}</style>
    </>
  );
};

GuidePage.propTypes = {
  additionalText: PropTypes.string,
  deals: PropTypes.array,
  errorCode: PropTypes.bool,
  feedConfig: PropTypes.object,
  listicleDeals: PropTypes.array,
  listicleView: PropTypes.bool,
  text: PropTypes.string,
};

export const getServerSideProps = wrapper.getServerSideProps(
  async (context) => {
    const { req, store } = context;
    if (req.url.includes('.html')) {
      return {
        redirect: {
          destination: req?.url?.replace(/.html/gi, ''),
          statusCode: 301,
        },
      };
    }
    await store.dispatch(getServerSideEssentials(req.url)).catch((error) => {
      // eslint-disable-next-line no-console
      console.log(`getServerSideEssentials error: ${error}`);
    });
    store.dispatch(setOnlySSR(false));

    return getStaticContent({ context, isguidePage: true, withDealFeed: true });
  },
);

export default connect()(GuidePage);
