import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ThemeContext from '../../providers/ThemeProvider';
import TwoByTwoDealCard from '../deal/TwoByTwoDealCard';

const ListicleDealBlock = ({ deals }) => {
  const theme = useContext(ThemeContext);

  return (
    <div className="deals-container">
      {deals.map((deal) => {
        return <TwoByTwoDealCard deal={deal} key={deal.id} />;
      })}
      <style jsx>{`
        .deals-container {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
          margin-bottom: 20px;
          margin-left: -25px;
          margin-right: -25px;
        }
        :global(.deals-container > div:nth-child(odd)) {
          margin-right: 0;
        }
        @media (min-width: ${theme.breakpoints.smUp}) {
          .deals-container {
            margin-left: 15px;
            margin-right: 15px;
          }
          :global(.deals-container > div:nth-child(odd)) {
            margin-right: 10px;
          }
        }
        @media (min-width: ${theme.breakpoints.lgUp}) {
          :global(.deals-container > div:nth-child(odd)) {
            margin-right: 15px;
          }
        }
        @media (min-width: ${theme.breakpoints.xlUp}) {
          :global(.deals-container > div:nth-child(odd)) {
            margin-right: 20px;
          }
        }
      `}</style>
    </div>
  );
};

ListicleDealBlock.propTypes = {
  deals: PropTypes.array,
};

export default ListicleDealBlock;
