// Container for guide page
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PAGE_TYPES } from '../../config/constants/page-types';
import {
  SEARCH_PAGE_SIZE,
  DEFAULT_TOTAL_GUIDE_DEALS,
} from '../../config/links/links';
import { DEFAULT_LOCATION } from '../../config/setup/setup';
import { trackPage } from '../../helpers/analytics';
import { guideGetKeyGenerator } from '../../helpers/guides';
import { getLocationShortName } from '../../helpers/location';
import { sanitiseText } from '../../helpers/sanitiseText';
import { useIsSSR } from '../../helpers/ssr';
import { parseWowcherPath } from '../../helpers/url';
import ThemeContext from '../../providers/ThemeProvider';
import ExpanderRelative from '../_generic/expander/ExpanderRelative';
import ConnectedBottomDeals, {
  LAYOUT_TYPES,
  template,
} from '../deal/ConnectedBottomDeals';
import { fetchDeals } from '../deal/ConnectedBottomDeals/helpers';

const GuideContainer = ({ deals, text, feedConfig, additionalText }) => {
  const router = useRouter();
  const theme = useContext(ThemeContext);
  const isSSR = useIsSSR();
  const location = useSelector((state) => state.locations.location);
  const locationShortName = getLocationShortName(location);
  const pageProps = {
    location: locationShortName,
    siteLocation:
      location && location.name
        ? location.name
        : DEFAULT_LOCATION[process.env.NEXT_PUBLIC_SITE || 'wowcher'].name,
  };
  const pageName = router?.query?.slug?.replace(/.html/gi, '') || '';
  const SEO_TEXT_MAX_HEIGHT = 160;
  const SEO_TEXT_MARGIN_BOTTOM = 20;

  const { pageType } = parseWowcherPath(router.asPath);
  const claimMyGift = pageType === PAGE_TYPES.claimExperienceGift;

  useEffect(() => {
    trackPage(pageProps, pageName);
  }, []);

  return (
    <>
      {text && (
        <div className="seo__content" id="seo-content-container">
          {pageName === 'hub' ? (
            <div dangerouslySetInnerHTML={{ __html: sanitiseText(text) }} />
          ) : (
            <ExpanderRelative
              centeredButton
              marginBottom={SEO_TEXT_MARGIN_BOTTOM}
              maxHeight={SEO_TEXT_MAX_HEIGHT}
            >
              <div dangerouslySetInnerHTML={{ __html: sanitiseText(text) }} />
            </ExpanderRelative>
          )}
        </div>
      )}
      {feedConfig && (
        <ConnectedBottomDeals
          fetcherFunction={fetchDeals}
          getKeyGenerator={guideGetKeyGenerator({
            ...feedConfig,
            userLocation: locationShortName,
          })}
          isGuidepage
          pageSize={feedConfig?.dataPageSize || SEARCH_PAGE_SIZE}
          templateType={
            claimMyGift ? LAYOUT_TYPES.claimTwoByTwo : LAYOUT_TYPES.twoByTwo
          }
          totalDeals={feedConfig?.totalDeals || DEFAULT_TOTAL_GUIDE_DEALS}
        />
      )}
      {isSSR &&
        deals?.length > 0 &&
        template({
          deals,
          path: router.asPath,
          theme,
          type: LAYOUT_TYPES.twoByTwo,
        })}
      {additionalText && (
        <div
          className="seo__content"
          dangerouslySetInnerHTML={{ __html: sanitiseText(additionalText) }}
          id="seo-content-container-bottom"
        />
      )}

      <style jsx>{`
        .seo__content {
          padding: 10px;
          background-color: ${theme.commoncolors.white};
          background-image: url(${theme.images.dealsplat});
          background-repeat: no-repeat;
          background-position: right -45px top -45px;
          background-size: 125px 125px;
          border-radius: 0;
          margin-bottom: 0;
        }
        @media (min-width: ${theme.breakpoints.smUp}) {
          .seo__content {
            padding: 10px 30px;
            box-shadow: ${theme.boxshadow};
            border-radius: 6px;
            margin-bottom: 24px;
            margin-left: 0;
            margin-right: 0;
          }
        }

        .seo__content :global(h1) {
          color: ${theme.colors.primaryonwhite};
          font-size: 22px;
          text-align: left;
          line-height: 33px;
          font-weight: bold;
          margin-right: 64px;
        }
        @media (min-width: ${theme.breakpoints.mdUp}) {
          .seo__content :global(h1) {
            font-size: 26px;
            line-height: 44px;
            margin-left: 64px;
            text-align: center;
          }
        }
        @media (min-width: ${theme.breakpoints.lgUp}) {
          .seo__content :global(h1) {
            font-size: 26px;
            line-height: 64px;
          }
        }
        .seo__content :global(.hero-image) {
          background-size: cover;
          background-position: center;
          margin-top: 0;
          height: 180px;
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          z-index: -1;
        }
        @media (min-width: ${theme.breakpoints.smUp}) {
          .seo__content :global(.hero-image) {
            margin-top: -20px;
            height: 370px;
          }
        }

        .seo__content :global(.bg-backdrop) {
          z-index: -1;
          width: 100%;
          height: 100%;
          position: fixed;
          background-size: cover;
          background-position: center center;
          top: 0;
          left: 0;
        }

        .seo__content :global(.seo-text) {
          overflow: hidden;
          margin-bottom: 0;
        }

        .seo__content :global(.seo-text-contracted) {
          height: 101px;
        }

        .seo__content :global(.row) {
          margin-left: 0;
          margin-right: 0;
        }

        .seo__content :global(.seo-text-expand-link) {
          cursor: pointer;
          display: block;
          margin-top: 10px;
        }

        .seo__content :global(.main-image) {
          max-width: 100%;
          width: 100%;
        }

        .seo__content :global(.seo-footer-button) {
          color: ${theme.commoncolors.white};
          text-align: center;
          text-decoration: none;
          font-size: 24px;
          bottom: 10px;
          left: 3%;
          right: 3%;
          padding: 6px 20px;
          position: fixed;
          width: 94%;
          z-index: 300;
        }
        .seo__content :global(h2),
        .seo__content :global(h3),
        .seo__content :global(h4),
        .seo__content :global(h5),
        .seo__content :global(h6) {
          font-weight: normal;
          margin-bottom: 10px;
          margin-top: 20px;
        }

        .seo__content :global(h2) {
          color: ${theme.colors.primaryonwhite};
          font-weight: bold;
          font-size: 24px;
          line-height: 24px;
        }

        .seo__content :global(h3) {
          font-size: 22px;
          line-height: 22px;
        }

        .seo__content :global(h4) {
          font-weight: bold;
          font-size: 20px;
          line-height: 20px;
        }

        .seo__content :global(h5) {
          font-size: 18px;
          line-height: 18px;
        }

        .seo__content :global(h6) {
          font-size: 16px;
          line-height: 16px;
        }

        .seo__content :global(ul) {
          margin: 0 0 16px 25px;
        }

        .seo__content :global(li) {
          list-style: none;
          position: relative;
        }

        .seo__content :global(li::before) {
          content: '';
          height: 6px;
          width: 6px;
          background-color: ${theme.colors.primaryonwhite};
          border-radius: 50%;
          font-weight: bold;
          display: inline-block;
          margin-left: 5px;
          margin-right: 5px;
          position: relative;
          top: -2px;
        }

        .seo__content :global(a) {
          text-decoration: underline;
        }

        .seo__content :global(p) {
          margin-bottom: 15px;
        }

        .seo__content :global(.term) {
          text-transform: capitalize;
        }

        .seo__content :global(.faq-question) {
          font-weight: 700;
        }

        .seo__content :global(.slide-panel + div) {
          display: block !important;
        }
      `}</style>
    </>
  );
};

GuideContainer.propTypes = {
  additionalText: PropTypes.string,
  deals: PropTypes.array,
  feedConfig: PropTypes.object,
  text: PropTypes.string,
};

export default GuideContainer;
